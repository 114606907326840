<template>
  <div>
    <br>
    <b-card>
      <b-skeleton
        animation="throb"
        width="85%"
      />
      </br>
      <b-skeleton
        animation="throb"
        width="55%"
      />
      </br>
      <b-skeleton
        animation="throb"
        width="70%"
      />
      <br></br>
      <b-skeleton
        animation="throb"
        width="70%"
      />
      <b-skeleton
        animation="throb"
        width="70%"
      />
      <b-skeleton
        animation="throb"
        width="70%"
      />
      <br></br>
      <b-skeleton
        animation="throb"
        width="80%"
      />
      <b-skeleton
        animation="throb"
        width="90%"
      />
      <b-skeleton
        animation="throb"
        width="100%"
      />
    </b-card>

  </div>
</template>

<script>
import {
  BRow,
  BSkeleton,
  BSkeletonTable,
  BCol,
  BSkeletonImg,
  BContainer,
  BCard,

} from 'bootstrap-vue'

export default {
  name: 'LeftSideSkeleton',
  components: {
    BRow,
    BCol,
    BSkeletonTable,

    BContainer,
    BSkeletonImg,
    BSkeleton,
    BCard,
  },
}
</script>

<style lang="CSS" scoped>

</style>
